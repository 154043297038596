
.login {
  position: absolute;
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}


.login-border {
  transition: background 0.7s;
  margin-top: 2rem;
  border-radius: 2rem;
  height: 12rem;
  width: 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login input[type="submit"] {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.7s;
}

.login span {
  letter-spacing: 2px;
}

.login input[type="password"], .login input[type="text"] {
  width: 65%;
  padding: 0.25rem 0;
  font-size: 1.5rem;
  line-height: 2.5rem;
  border-radius: 0.2rem;
}

.login input {
  border: 0;
  outline: none;
  background: hsl(30,10%,86%);
  transition: background 0.3s;
  text-align: center;
  margin-top: 1.5rem;
}
.login input:focus {
  background: hsl(30,10%,80%);
}

.login-title {
  font-size: 2rem;
  font-family: Candara;
}

.login input.login-error   { background: #e14023;            }
.login input.login-success { background: hsl(144, 40%, 50%); }
