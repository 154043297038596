* {
  margin: 0;
  padding: 0;
  outline: 0;
}
html, body, #root, .wrapper {
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
}

em {
  font-style: italic;
  font-weight: normal;
  background: red;
  color: white;
}

.lang-he em {
  font-style: normal;
  font-weight: 1000;
}


.content {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.content, header {
  max-width: 2000px;
}

header {
  flex: 0 0 auto;
}

footer {
  margin-top: 0;
  padding: 4px;
  line-height: 1.4;
  font-size: 90%;
  color: #888;
  position: relative;
}
.row {
  display: flex;
  padding: 0 9px;
  border: 7px solid transparent;
  border-top: 0;
  border-bottom: 0;
}

.row:hover {
  background: hsl(30,30%,96%);
  border-color: black;
  cursor: text;
}

.editing .row:hover {
  background: inherit;
  cursor: default;
}

.left-column, .right-column {
  padding: 0.25em 0.25em;
  position: relative; /* for the footnote popup */
}

.left-column {
  width: 41.5%;
}
.right-column {
  width: 58.5%;
}
.lang-he, .lang-he textarea {
  font-size: 27px;
  line-height: 32px;
  direction: rtl;
 /* font-family: 'Hadassah Friedlaender';*/
  font-family: 'FrankRuehl';
}
.lang-ru, .lang-ru textarea {
  font-size: 23px;
  line-height: 32px;
  font-family: 'Cambria';
  letter-spacing: -0.2px;
}

textarea {
  font-family: inherit;
  overflow: hidden;
}

.paragraph p {
  padding: 4px 4px;
  display: inline-block;
  transition: background 0.5s;
  white-space: pre-line;
}

.dirty .paragraph p {
  background: hsl(20,80%,60%);
  color: white;
}


.middle-column {
  flex-shrink: 0;
  width: 50px;
  font-family: monospace;
  display: flex;
  flex-direction: column;
  justify-items: end;
  align-items: center;
  padding: 10px 0;
}

.chapter-title {
  font-size: 70px;
  color: hsl(200,70%,30%);
  text-align: center;
  font-weight: bold;
}

.speaker-tag {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  text-align: center;
  text-transform: uppercase;
  font-size: 22px;
  margin-top: 0.1em;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  background: #eee;
  cursor: pointer;
}

.seq-number {
  display: block;
  font-size: 14px;
  color: #aaa;
  white-space: nowrap;
}

.changing-speaker {
  border: 4px solid black;
}


.highlighted {
  background: black;
  color: white;
}

.extraspace, .problems-filter .stat-name {
  background: hsl(10,90%,80%);
  color: black;
}
.extraspace { white-space: pre; }

textarea {
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 3px rgba(0,0,0,20%);
  padding: 4px 4px;
  width: 100%;
  height: auto;
  outline: none;
  border: none;
  border-radius: 5px;
  display: block;
}


.filter-tag-list {
  text-align: left;
  padding: 0 0.1em;
}

.filter-tag {
  font-size: 12px;
  display: inline-flex;
  margin: 2px 2px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  background: white;
  align-items: baseline;
  overflow: hidden;
  transition: background 0.3s, box-shadow 0.2s;
}
.filter-tag:hover {
  box-shadow: 0 0 6px 1px rgba(0,0,0,0.2);
}

.selected-filter-tag  {
  background: black !important;
  color: white !important;
}

.notselected-filter-tag, .notselected-filter-tag .stat-count {
  background: #bbb !important;
  color: black !important;
  border-color: #bbb !important;
}

.notselected-filter-tag:hover {
  box-shadow: none;
}

.selected-filter-tag .stat-name {
  border-color: white;
}

.stat-name  {
  padding: 3px 8px;
  display: inline-block;
  overflow: hidden;
}


.stat-count {
  font-family: monospace;
  padding: 3px 0px;
  display: inline-block;
  width: 2em;
}

.chapter-filter .stat-name {
  background: hsl(200,70%,40%);
  color: white;
}


.needsWork-filter .stat-name {
  background: #444;
  color: white;
}


#search-box {
  font-size: 30px;
  border: 10px solid white;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-radius: 8px;
  outline: 0;
  padding: 6px;
  background: white;
  margin-right: 20px;
  transition: box-shadow 0.3s;
  width: 40%;
}
#search-box:focus {
  box-shadow: 0 0 10px 2px rgba(0,0,0,0.2)
}
.match-stats {
  display: inline-block;
  flex-grow: 1;
}


#book {
  width: 100%;
  height: 100%; 
  transition: background 0.3s;
  background: hsl(30,50%,99%);
  /*overflow-y: scroll;*/
  overflow: hidden;
}
.book-wrapper {
  transition: box-shadow 0.3s;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border: 1px solid #bbb;
}
.book-wrapper:focus-within {
  box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
}

.first-row {
  padding-top: 2em;
  position: relative;
}
.last-row {
  padding-bottom: 2em;
  position: relative;
}

.first-row:before,  .last-row:after {
  content: '';
  position: absolute;
  left: 0;
  color: #bbb;
  width: 100%;
  height: 1em;
}

.first-row:before {
  top: 0;
  background: linear-gradient(rgba(0,0,0,.1), rgba(0,0,0,0));
}

.last-row:after {
  bottom: 0;
  background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,.1));
}


.editing #book {
  background: #ddd;
}

header {
  border-bottom: 8px solid transparent;
  user-select: none;
  padding: 8px;
  padding-bottom: 0em;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}


.user {
  justify-self: end;
}

.docx {
  margin-right: 1em;
  margin-bottom: -8px;
}

.wrapper {
  background: #ddd;
  transition: background 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapper.is-filtered {
  background: hsl(50,40%,85%);
}

.wrapper.is-changing-speaker {
  background: black;
}


.left-side {
  text-align: right;
}

.left-side, .right-side {
  padding: 0em 1em;
  flex: 0 0 ;
}

.network-writes-pending header {
  border-color:  hsl(20,80%,60%);
}


.footnote {
  border-radius: 5px;
  cursor: pointer;
  top: -0.2em;
  position: relative;
  padding: 0 0.25em;
  height: 0.8em;
  display: inline-block;
  line-height: 1em;
}
.footnote-missing       { background: hsl(10,100%,50%); color: white; }
.footnote-missing:hover { background: hsl(10,100%,60%); color: white; }

.footnote-exists       { background: hsl(177,99%,47%);  color: black; }
.footnote-exists:hover { background: black; color: white; }

.footnote-popup {
  position: absolute;
  background: hsl(50,80%,70%);
  color: #444;
  padding: 4px 10px;
  border: 1px solid #777;
  font-size: 70%;
  line-height: 1.2;
  font-family: arial;
  max-width: 500px;
  box-shadow: 6px 6px 6px 0px rgba(0,0,0,0.3);
  animation: fadein 0.3s;
  z-index: 1;
  white-space: pre-line;
  width: max-content;
}

.highlight {
  cursor: pointer;
}

@keyframes fadein {
  from { opacity: 0 }
  to { opacity: 1 }
}


footer a, footer a:visited {
  color: #555;
}


.needs-work .left-column, .needs-work .right-column {
  color: white;
  background: rgba(0,0,0,80%);
  border-radius: 9px;
}

.needs-work-toggle {
  display: inline-block;
  border: 1px dashed #ccc;
  cursor: pointer;
}

.needs-work .needs-work-toggle {
  background: black;
  color: white;
  border: 1px solid black;
}


.is-perfect .left-column, .is-perfect .right-column {
  background: rgba(40,150,30,10%);
  border-radius: 9px;
}

.blockquote {
  white-space: pre-line;
  display: inline-block;
  padding-right: 0;
  padding-left: 2em;
  font-style: italic;
}
.lang-he .blockquote {
  padding-right: 2em;
  padding-left: 0;
}
.highlight, .highlights-filter .stat-name {
  background: hsl(55,90%,70%);
}
.needs-work .highlight {
  color: black;
}

.marker {
  outline: 1px dashed;
}


.highlight {
  box-shadow: 0px 0px 2px rgba(0,0,0,0.3);
}

.highlight:hover {
  background: hsl(55,90%,50%);
}
.highlights-filter .stat-name { color: black; }

.reveal-button {
  font-size: 14px;
  color: transparent;
}
.reveal-button.active {
  cursor: pointer;
  color: hsl(57,70%,30%);
}


.filter-tag-list { padding-bottom: 0;
                   margin-bottom: 0;
                   display: inline-block;
                   box-sizing: border-box;
                   vertical-align: top; }
.filter-chapters { order: 1; text-align: left; }
.book-wrapper    { order: 3; }
.filter-speakers { order: 2; }
.stat-name { border-right: 1px solid; }
footer { padding-right: 50px; display: inline-block; }
@media screen and (min-width: 1800px) {
  .content         { display: flex; flex-direction: row; }
  .filter-tag-list { flex: 0 0; padding-bottom: 0.5em; margin-bottom: 0.5em }
  .filter-chapters { order: 1; text-align: right; }
  .stat-count { padding: 3px 8px; }
  .book-wrapper    { order: 2; }
  .filter-speakers { order: 3; }
  .filter-speakers .stat-name { border-right: 0; border-left: 1px solid; order: 2; }
  .needsWork-filter { margin-top: 1em; margin-left: 0; }
  footer { padding-right: 0; margin-top: 6px; padding: 10px; display: block; }
}